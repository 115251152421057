<template>
  <div id="auth-layout">
    <referrer-money-gps v-if="showReferrerMoneyGps" />
    <div class="bg-sand">
      <div class="inner-container">
        <app-header
          :centre="true"
          class="auth-header"
        ></app-header>
      </div>
    </div>
    <div class="bg-sand-white">
      <div class="inner-container collapse pt-4">
        <div class="content-box">
          <transition
            mode="out-in"
            name="fade"
          >
            <router-view :key="$route.path" />
          </transition>
        </div>
      </div>
    </div>
    <anon-tracking />
    <div class="chat-buffer"></div>
  </div>
</template>

<script>
import ReferrerMoneyGps from '../components/content/ReferrerMoneyGps'
import AnonTracking from '../components/site/AnonTracking'
import AppHeader from '../components/site/AppHeader'

export default {
  name: 'Authentication',
  components: {
    ReferrerMoneyGps,
    AnonTracking,
    AppHeader,
  },
  data: () => ({
    test: null,
  }),
  created() {
    window.scrollTo(0, 0)
  },
  computed: {
    showReferrerMoneyGps() {
      let ref = this.$store.getters['anon/getReferralTracking']
      return (
        this.$route.path === '/get-started' && ref && ref.id === 'fiduciary'
      )
    },
  },
}
</script>

<style lang="scss">
.auth-header {
  height: 140px;
  max-width: 1100px;
  padding: 0 24px;
  margin: auto;

  @media only screen and (max-width: 599px) {
    height: 112px;
  }
}

#auth-layout {
  .inner-container {
    max-width: 640px;
  }
  .auth-form-inner {
    max-width: 420px;
    margin: 0 auto;
  }
}
</style>
