<template>
  <div class="money-gps">
    <img
      src="https://moneygps.com.au/wp-content/uploads/2021/09/Group-58@2x.png"
    />
    <p>
      Willed is delighted to have moneyGPS as its partner, in offering clients
      access to the leading online Will service in the country
    </p>
  </div>
</template>

<script>
export default {
  name: 'ReferrerMoneyGps',
}
</script>

<style>
.money-gps {
  background-color: #152845;
  color: #ffffff;
  padding: 12px;
  text-align: center;
  font-size: 16px;
}

.money-gps img {
  width: 140px;
  height: auto;
  display: block;
  margin: 0 auto 12px;
}
</style>
